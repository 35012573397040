<template>
	<div class="view-scenarios">
		<div class="view-content">
			<h1 class="title">LIBRARY OF SCENARIOS</h1>
			<div class="search-form">
				<input type="text" class="input" v-model="q" placeholder="Search by scenario name..." autofocus />
			</div>
			<div v-if="scenarios.length" class="content">
				<div class="scenario-items">
					<div v-for="scenario in visibleScenarios" :key="scenario.id" class="scenario-item-wrap">
						<scenario-item :scenario="scenario" v-tap="($event) => select($event, scenario)" />
					</div>
				</div>
			</div>
			<div v-else class="loader">
				<ui-icon name="button-loader" :size="64" />
			</div>
		</div>
	</div>
</template>

<script>
import Apollo from '@/apollo/apollo-client'
import ScenarioItem from '@/components/scenario-item'

export default {
	name: 'view-scenarios',
	data() {
		return {
			scenarios: [],
			q: '',
		}
	},
	computed: {
		visibleScenarios() {
			if (this.q) {
				const q = this.q.toLowerCase()
				return this.scenarios.filter(scenario => scenario.name.toLowerCase().indexOf(q) > -1)
			}
			return this.scenarios
		}
	},
	async created() {
		this.scenarios = await Apollo.query('ALL_SCENARIOS')
	},
	components: { ScenarioItem }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

.view-scenarios
	width 100%
	height 100%
	overflow auto
	padding 48px
	background-color var(--color-bg)
	.view-content
		width 100%
		max-width 900px
		margin 0 auto
		.title
			display block
			margin 0 0 24px 0
			font-family 'Josefin Sans'
			font-size 3.2rem
			font-weight 400
			text-align center
			color var(--color-text)
		.search-form
			height 56px
			border-bottom 2px solid var(--color-scenario-item-border)
			.input
				display block
				width 100%
				height 40px
				padding 0 24px
				border none
				background var(--color-scenario-search-input-bg)
				border-radius 40px
				font inherit
				color dark
				outline none
				&:focus
					background-color var(--color-scenario-search-input-bg-focus)
		.content
			.scenario-items
				padding 32px 0
				.scenario-item-wrap
					margin 0 0 24px 0
					&:last-child
						margin 0
	.loader
		height 30vh
		flex center center
		
</style>
